const Text = {
    is:
        `
# Notkunarskilmálar Tilkynna

Eftirfarandi skilmálar varða notkun þína á þjónustum Tilkynna, meðal annars vefsíðum, hugbúnaði, öðrum vörum og þjónustu. Með því að nota þjónustu Tilkynna samþykkir þú notkunarskilmála, vinnslusamning og persónuverndarstefnu Tilkynna.

## Þjónustan
Tilkynna er íslenskt vefkerfi sem einfaldar uppljóstrunarferlið með því að taka á móti tilkynningum og koma þeim í réttan farveg innan viðkomandi lögaðila. 

Lögaðili stofnar aðgang að kerfi Tilkynna sem býr til sérstaka vefslóð fyrir viðkomandi lögaðila. Þar getur tilkynnandi fyllt inn upplýsingar varðandi málið. Tilkynnandi getur sent tilkynninguna nafnlaust, með nafni sínu og netfangi eða rafrænum skilríkjum. Kerfið gerir lögaðila einnig kleift að eiga nafnlaus samskipti við tilkynnanda, t.d. ef þörf er á að afla frekari upplýsinga varðandi málið, ásamt því að gefa tilkynnanda upplýsingar um stöðu málsins.

Stofnandi aðgangs getur bætt við öðrum meðlimum uppljóstrunarteymis lögaðila inn í umsýslukerfi Tilkynna og sendist tilkynning á netfang þeirra í kjölfarið. Hver notandi fær útbúinn einstakan aðgang og lykilorð að kerfinu og ber ábyrgð á að passa upp á öryggi þessara upplýsinga. Nauðsynlegt er fyrir hvern notanda að staðfesta netfang og skráningu til að fá aðgang að kerfinu. 

Fyllsta öryggis og trúnaðar er gætt við meðferð og meðhöndlun tilkynninga. Farið er með persónuupplýsingar í samræmi við grundvallarsjónarmið og reglur um persónuvernd.


## Notkun á þjónustunni
Til þess að nota þjónustu Tilkynna þá býr lögaðili til þjónustureikning í gegnum tilkynna.is þar sem veittar eru nauðsynlegar stofnupplýsingar. Lögaðili ábyrgist að allar upplýsingar sem hann lætur í té séu nákvæmar og réttar, og er meðvitaður um það að ekki verður hægt að veita lögaðila þjónustu Tilkynna nema allar upplýsingar séu fyrir hendi og séu réttar. 

Lögaðili ber ábyrgð á að gæta trúnaðar varðandi notendanafn og lykilorð inn á kerfi Tilkynna og öll gögn sem eru veitt í gegnum kerfið. Tilkynna er ekki ábyrgt fyrir tjóni sem gæti hlotist af því ef lögaðili uppfyllir ekki þessar kröfur. Lögaðili samþykkir að láta Tilkynna / Slidesome ehf. tafarlaust vita um brot á öryggi eða óleyfilega notkun á kerfi Tilkynna. 

Tilkynna / Slidesome ehf. er ekki ábyrgt fyrir skaða sem gæti hlotist af upplýsingum og gögnum sem hlaðið er inn í kerfi af notendum Tilkynna, hvort sem það er skaði á tölvubúnaði, forritum eða öðru tengt búnaði og vefþjónum lögaðila. Lögaðili skal ávallt beita fyllstu varúðar og öryggis þegar skjöl og skrár sem hlaðnar eru upp af notendum í gegnum kerfi Tilkynna eru opnaðar á tölvubúnaði lögaðila. 

Tilkynna / Slidesome ehf. er ekki ábyrgt fyrir neinum skaða sem gæti hlotist vegna upplýsinga sem notendur þjónustunnar veita í gegnum kerfi Tilkynna, hvort sem upplýsingarnar eru veittar undir nafni eða nafnlaust. 


## Aðgangur að þjónustunni
Áskrifendur Tilkynna hafa aðgang að umsaminni þjónustu Tilkynna. Greidd áskrift veitir lögaðila óframseljanlegt, afturkallanlegt og takmarkað leyfi til að nota og fá aðgang að þjónustunni. Lögaðili hefur rétt á að nota þjónustuna eingöngu í samræmi við virkni þjónustunnar, á því tímabili sem greitt hefur verið fyrir. 


## Þróun og breytingar
Tilkynna þjónustan er í stöðugri þróun og Tilkynna áskilur sér rétt til að breyta og uppfæra þjónustuna. Tilkynna mun láta lögaðila vita með 30 daga fyrirvara ef breytingar á þjónustunni eru líklegar til að hafa veruleg og neikvæð áhrif á upplifun notenda af þjónustunni. Lögaðili er ábyrgur fyrir því að upplýsingar um netföng og aðrar samskiptaleiðir séu réttar. Ef lögaðili er ekki samþykkur breytingunum hefur lögaðili rétt á að segja upp þjónustunni með 30 daga uppsagnarfresti, sem tekur gildi frá og með degi uppsagnar á áskrift. Eftirstöðvar áskriftartímabils verða þó ekki endurgreiddar að hluta til, né að öllu leyti. Ef lögaðili heldur áfram að nota þjónustuna eftir 30 daga uppsagnarfrestinn þá metur Tilkynna það svo að lögaðili sé samþykkur breytingum á þjónustunni, og þeim skilmálum og skilyrðum sem breytingarnar hafa í för með sér. 


## Gögn notenda
Gögn notenda eru allar þær upplýsingar sem lögaðili eða notendur láta í té innan vefsvæðis eða samskiptagáttar Tilkynna. Lögaðili er einn ábyrgur fyrir gögnum notenda og tekur á sig alla áhættu sem fylgir notkun á þessum gögnum. Ef lykilorð glatast getur lögaðili haft samband við Tilkynna og fengið nýtt lykilorð. Tilkynna er ekki ábyrgt fyrir því að upplýsingar frá notendum glatist eða rati í rangar hendur vegna þess að lögaðili hefur farið óvarlega með lykilorð eða upplýsingar notenda innan lögaðila. 

Lögaðili og notendur kerfisins samþykkja að hlaða ekki upp, né senda eða dreifa skaðlegum hugbúnaði sem ætlað er að breyta tölvukerfi eða gögnum, svo sem tölvuvírusum og ormum, eða til að fá óheimilan aðgang að þjónustunni eða öðrum kerfum sem eru tengd þjónustunni. 

Tilkynna tryggir framboð þjónustunnar allan sólarhringinn, alla daga ársins. Ef framboð á hverjum mánuði er minna en 99% hefur viðskiptavinurinn rétt á uppbótartíma við þjónustutímann sem tapaðist. Tilkynna áskilur sér rétt til að stöðva þjónustuna tímabundið, vegna uppfærslu eða breytinga, að hámarki 15 mínútur á hverjum mánuði. 

## Áskrift og uppsögn
Reikningur fyrir áskrift er sendur út einu sinni á ári og eru 12 mánuðir greiddir fyrirfram. Lögaðili áskilur sér að greiða reikninginn eigi síðar en á eindaga reiknings. Ef reikningur er ekki greiddur á eindaga þá hefur Tilkynna rétt á því að rukka lögbundin innheimtugjöld og dráttarvexti í samræmi við gildandi lög og reglugerðir, eða stöðva þjónustuna. Viðbótarþjónusta, þegar þess er krafist, sem ekki er innifalin í árlegu leyfisgjaldi er innheimt skv. gjaldskrá Tilkynna. 

Gildistími þjónustusamningsins eru 12 mánuðir. Ef lögaðili hefur ekki sagt upp þjónustunni fyrir lok 12 mánaða tímabilsins þá mun áskriftin halda áfram og verður reikningur sendur út árlega þar til lögaðili segir upp þjónustunni. 

## Takmarkanir
Lögaðili hefur ekki leyfi til að selja, leigja, framselja eða dreifa þjónustunni, eða á annan hátt nýta sér kerfi Tilkynna á annan hátt en samið er um. Lögaðila er ekki heimilt að afrita, breyta, búa til afleidd verk eða endurhanna neinn hluta kerfis Tilkynna nema með skriflegu samþykki eigenda Tilkynna. Lögaðila er ekki heimilt að byggja upp svipaða þjónustu og þá sem Tilkynna býður upp á, eða aðra samkeppnishæfa þjónustu. 

## Fyrirvarar
Tilkynna veitir þjónustuna í samræmi við lýsingu hennar og með þeim takmörkunum sem lýst er í þessum notkunarskilmálum. Tilkynna veitir ekki lögfræðilega ráðgjöf. Þær upplýsingar og ráðgjöf sem Tilkynna veitir teljast ekki til lögfræðiráðgjafar og ber Tilkynna ekki ábyrgð á slíkri notkun. Tilkynna ber ekki ábyrgð á notkun lögaðila á þjónustunni, í samræmi við gildandi lög og reglur.

`,
    en:
        `
# Terms of Use
Please read these Terms of Use as they apply to your use of Tilkynna. If you do not agree to these Terms of Use then do not use the service. Terms of use are subject to change at any time and continued use of the service is deemed acceptance of the changes.

## Use of services
The Service may only be used for lawful purposes and in a lawful manner. By using the service, you agree to comply with all applicable laws and regulations regarding this service and its use. You acknowledge that all information you provide through the Service is true and correct, to the best of your knowledge, and is intended to inform about misconduct or reprehensible conduct in the activities of legal entities.

By using the system, you agree not to upload or transmit or distribute malicious software intended to modify computer systems or data, such as computer viruses or worms, or to gain unauthorized access to the service, other users or other systems that are related to the service.

## Personal information
Reporting services are designed to protect anonymity and protect the data of the sender, as well as enable users to communicate anonymously with each other. Also, do not report metadata, including your IP address, that could reveal who you are.


## Liability
Use of the service is at the user's own risk. Notify, to the extent permitted by law, any liability that may arise as a result of the use of this service. By using Tilkynna's services, you agree to make no claims for compensation to Tilkynna / Slidesome ehf. due to damage that may result from the use of the service. This includes downloading, uploading data, accessing data, information or other content that can be accessed through Tilkynna.

## Applicable law
Terms of use are governed by and interpreted in accordance with Icelandic law

`
}

export default Text
