import React from 'react'
import Terms from '../../../../components/Legal/Organisation/Terms'

const Page = ({ location }) => {
    return (
        <Terms
            language="en"
            location={location}
        />
    )
}

export default Page
