import React from 'react'
import ReactMarkdown from 'react-markdown'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import localization from '../../../localization'
import LegalNav from './LegalNav'

import ReportTerms from './Text/ReportTerms'
import { legal } from '../index.module.scss'
import SubpageHero from '../../SubpageHero'

const Terms = ({ language, location }) => {
    return (
        <>
            <SEO
                title={localization[language].terms.termsOfUse}
                description={localization[language].terms.termsOfUse}
            />
            <Layout isFrontpage>
                <SubpageHero>
                    <div>
                        <h1>{localization[language].terms.termsOfUse}</h1>
                        <LegalNav language={language} location={location} />
                    </div>
                </SubpageHero>
                <div className={legal}>
                    <ReactMarkdown
                        children={ReportTerms['is']}
                        allowDangerousHtml={true}
                        escapeHtml={false}
                        skipHtml={false}
                        linkTarget="_blank"
                    />
                </div>
            </Layout>
        </>
    )
}

export default React.memo(Terms)
